import "@iguhealth/components/dist/index.css";
import "@iguhealth/questionnaire-component/css/antd.min.css";
import "@iguhealth/questionnaire-component/css/custom.css";

import React, { useState, useEffect, memo } from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import {
  Outlet,
  createBrowserRouter,
  useNavigate,
  RouterProvider,
} from "react-router-dom";

import { QuestionnaireResponse } from "@iguhealth/fhir-types/r4/types";
import { QuestionnaireReact } from "@iguhealth/questionnaire-component";
import { TenantId } from "@iguhealth/jwt";

import {
  Button,
  Loading,
  IGUHealthProvider,
  ProfileDropdown,
  useIGUHealth,
} from "@iguhealth/components";

import reportWebVitals from "./reportWebVitals";
import { getQuestionnaire } from "./data/questionnaire";
import { getClient } from "./data/client";

import "./index.css";
import { R4 } from "@iguhealth/fhir-types/versions";

function LoginWrapper() {
  const iguhealth = useIGUHealth();

  return (
    <>
      {!iguhealth.isAuthenticated ? (
        <div className="h-screen flex flex-1 justify-center items-center flex-col">
          <Loading />
          <div className="mt-1 ">Loading...</div>
        </div>
      ) : (
        <div className="h-screen flex flex-col">
          <Outlet />
        </div>
      )}
    </>
  );
}

function ServiceSetup() {
  const iguhealth = useIGUHealth();
  const client = iguhealth.isAuthenticated ? iguhealth.getClient() : undefined;
  const [c, setClient] = useRecoilState(getClient);

  React.useEffect(() => {
    if (client) {
      setClient(client);
    }
  }, [setClient, iguhealth.isAuthenticated]);

  return (
    <>
      {c ? (
        <>
          <div className="px-4 sm:px-6 lg:px-8 border-b sticky top-0 bg-white z-10">
            <div className="flex h-16 items-center justify-between">
              <div className="flex flex-grow">
                <img
                  alt="Notovox"
                  className="h-12"
                  src={`${process.env.PUBLIC_URL}/notovox_screening.png`}
                />
              </div>
              <ProfileDropdown
                user={{
                  email: iguhealth.user?.email,
                  name: iguhealth.user?.given_name || iguhealth.user?.email,
                }}
              >
                <span
                  className="cursor-pointer block px-4 py-2 text-sm text-slate-800 hover:text-blue-800 hover:bg-blue-100"
                  onClick={() => {
                    iguhealth.logout(window.location.origin);
                  }}
                >
                  Sign out
                </span>
              </ProfileDropdown>
            </div>
          </div>
          <Outlet />
        </>
      ) : undefined}
    </>
  );
}

function TenantCheck() {
  return (
    <>
      <Outlet />
    </>
  );
}

const QuestionnairReactMemo = memo(
  QuestionnaireReact,
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.questionnaire?.id === nextProps.questionnaire?.id &&
      prevProps.questionnaireResponse?.id ===
        nextProps.questionnaireResponse?.id
    );
  }
);

function QuestionnaireForm() {
  const [questionnaireResponse, setQuestionnaireResponse] =
    useState<QuestionnaireResponse>();
  const navigate = useNavigate();
  const client = useRecoilValue(getClient);
  const questionnaires = useRecoilValue(getQuestionnaire("ahc-questionnaire"));
  return (
    <div className="flex justify-center">
      <div className="container p-2">
        {questionnaires.resources.length > 0 && (
          <QuestionnairReactMemo
            id={questionnaires.resources[0].id || "q"}
            questionnaire={questionnaires.resources[0]}
            questionnaireResponse={questionnaireResponse}
            onChange={(qr) => {
              setQuestionnaireResponse(qr);
            }}
            // expand={async (url) => {
            //   const response = (await fhirClient?.invoke_type(
            //     r4.ValueSetExpand.Op,
            //     {},
            //     "ValueSet",
            //     {
            //       url,
            //     }
            //   )) as ValueSet;
            //   return response;
            // }}
          />
        )}
        <Button
          disabled={!questionnaireResponse}
          onClick={() => {
            if (questionnaireResponse) {
              client.create({}, R4, questionnaireResponse);
              navigate("/submitted");
            }
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

function Questionnaire() {
  return (
    <React.Suspense
      fallback={
        <div className="w-screen h-screen flex flex-1 justify-center items-center flex-col">
          <Loading />
          <div className="mt-1 ">Loading...</div>
        </div>
      }
    >
      <QuestionnaireForm />
    </React.Suspense>
  );
}

function IGUHealthWrapper() {
  return (
    <IGUHealthProvider
      domain={process.env.REACT_APP_IGUHEALTH_DOMAIN ?? ""}
      tenant={(process.env.REACT_APP_IGUHEALTH_TENANT ?? "") as TenantId}
      clientId={process.env.REACT_APP_IGUHEALTH_CLIENT_ID ?? ""}
      scope={"openid profile fhirUser"}
      redirectUrl={window.location.origin}
    >
      <Outlet />
    </IGUHealthProvider>
  );
}

function EmptyWorkspace() {
  return <span>Not a part of workspace</span>;
}

function Submitted() {
  const iguhealth = useIGUHealth();
  useEffect(() => {
    setTimeout(() => {
      iguhealth.logout(window.location.origin);
    }, 4000);
  }, [iguhealth]);

  return (
    <div className="flex flex-1 justify-center items-center">
      <span className="text-lg font-semibold">Questionnaire Submitted</span>
    </div>
  );
}

const router = createBrowserRouter([
  {
    id: "iguhealth-wrapper",
    element: <IGUHealthWrapper />,
    children: [
      {
        id: "login",
        element: <LoginWrapper />,
        children: [
          {
            path: "/",
            element: <TenantCheck />,
            children: [
              {
                id: "empty-workspace",
                path: "/no-workspace",
                element: <EmptyWorkspace />,
              },
              {
                path: "/",
                element: <ServiceSetup />,
                children: [
                  {
                    id: "questionnaire",
                    path: "/",
                    element: <Questionnaire />,
                  },
                  {
                    id: "submitted",
                    path: "/submitted",
                    element: <Submitted />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  );
}

export default App;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
